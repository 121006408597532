import axios from "../utils/request";

//获取首页信息
export function getindexData(data) {
  return axios({
    url: "/clodchainAM/web/homePage",
    method: "post",
    ...data
  });
}
