<template>
  <div>
    <div>
      <!-- <el-select
        @change="getindexDatas"
        v-model.number="deptId"
        placeholder="请选择组织"
      >
        <el-option
          v-for="item in options"
          :key="item.deptId"
          :label="item.deptName"
          :value="item.deptId"
        >
        </el-option>
      </el-select> -->
      <select-tree
        v-if="loading.tree"
        style="width:168px"
        ref="selectTree"
        :props="props"
        placeholder="请选择组织"
        :options="options"
        :value="deptId"
        :clearable="false"
        :accordion="true"
        @getValue="getValue($event)"
      />
      <div class="contents">
        <div class="content_item pointer" @click="getindexDatas({})">
          <p class="number">{{ assetSum }}</p>
          <div style="display: flex;justify-content: left;margin-top: 10px">
            <p class="content_item_title">资产总量</p>
            <img src="../../assets/images/pic_number_green.png" alt="图片" />
          </div>
        </div>
        <div
          class="content_item pointer"
          @click="getindexDatas({ onlineStatus: 1 })"
        >
          <p class="number">{{ onlineNum }}</p>
          <div style="display: flex;justify-content: left;margin-top: 10px">
            <p class="content_item_title">在线数量</p>
            <img src="../../assets/images/pic_number_purple.png" alt="图片" />
          </div>
        </div>
        <div
          class="content_item pointer"
          @click="getindexDatas({ onlineStatus: 2 })"
        >
          <p class="number">{{ offLinenNum }}</p>
          <div style="display: flex;justify-content: left;margin-top: 10px">
            <p class="content_item_title">离线数量</p>
            <img src="../../assets/images/pic_number_blue.png" alt="图片" />
          </div>
        </div>
        <div class="content_item">
          <p class="number">{{ warningNum }}</p>
          <div style="display: flex;justify-content: left;margin-top: 10px">
            <p class="content_item_title">资产报警数量</p>
            <img src="../../assets/images/pic_number_orange.png" alt="图片" />
          </div>
        </div>
        <div
          class="content_item pointer"
          @click="getindexDatas({ visitorFlow: 1 })"
        >
          <p class="number">{{ ridership }}</p>
          <div style="display: flex;justify-content: left;margin-top: 10px">
            <p class="content_item_title">客流量</p>
            <img src="../../assets/images/pic_number_blue.png" alt="图片" />
          </div>
        </div>
        <div
          class="content_item pointer"
          @click="getindexDatas({ machineOpenRate: 1 })"
        >
          <p class="number">{{ action }}</p>
          <div style="display: flex;justify-content: left;margin-top: 10px">
            <p class="content_item_title">开机率</p>
            <img src="../../assets/images/pic_number_blue.png" alt="图片" />
          </div>
        </div>
      </div>
      <div class="map" id="map"></div>
    </div>
  </div>
</template>

<script>
import { queryDeptAll } from "@/api/AssetManagement.js";
import { getindexData } from "@/api/index.js";
import * as echarts from "echarts/core";
import china from "@/assets/map/china.json";
export default {
  name: "index",
  data() {
    return {
      deptId: Number(localStorage.getItem("deptId")),
      assetSum: 0,
      onlineNum: 0,
      offLinenNum: 0,
      warningNum: 0,
      ridership: 0,
      action: 0,
      options: [],
      value: "",
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      },
      loading: {
        tree: false
      }
    };
  },
  created() {
    this.getOrganization();
    this.getindexDatas();
  },
  mounted() {},
  methods: {
    getValue(value) {
      this.deptId = value;
      this.getindexDatas(value);
    },
    //地图
    map() {
      // 基于准备好的dom，初始化echarts实例
      var myChartContainer = document.getElementById("map");
      var myChartChina = echarts.init(myChartContainer);
      echarts.registerMap("china", china);

      // 绘制图表
      var optionMap = {
        title: {
          padding: [20, 20, 20, 20],
          text: "全国客户分布"
        },
        tooltip: {
          trigger: "item",
          formatter: params => {
            if (params.data) {
              return `${params.name}<br/>${params.data.value}`;
            } else {
              return `${params.name}<br/>-`;
            }
          }
        },
        visualMap: {
          min: 0,
          max: 1500,
          left: "10%",
          top: "bottom",
          text: ["高", "低"],
          calculable: true,
          color: ["#46799F", "#E3E8EE"]
        },
        // selectedMode: "single",
        series: [
          {
            type: "map",
            mapType: "china",
            itemStyle: {
              normal: {
                borderColor: "rgba(0, 0, 0, 0.2)"
              },
              emphasis: {
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            showLegendSymbol: true,
            label: {
              show: true
            },
            data: this.mapDat
            // nameMap: {
            //   ...this.$store.state.nameMap
            // }
          }
        ]
      };

      myChartChina.setOption(optionMap);
      window.onresize = function() {
        // resizeMyChartContainer();
        myChartChina.resize();
      };
    },

    getOrganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.options.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.options.push(value);
          }
        });
        this.loading.tree = true;
      });
    },
    getindexDatas(value) {
      const config = {
        data: {
          deptId: this.deptId,
          userId: localStorage.getItem("userId"),
          ...value
        }
      };
      getindexData(config).then(res => {
        let datas = res.data.data;
        this.assetSum = datas.deviceNum;
        this.onlineNum = datas.onlineNum;
        this.offLinenNum = datas.offlineNum;
        this.warningNum = datas.warningNum;
        this.ridership = datas.vistorNum;
        this.action = datas.onlineRate + "%";
        this.mapDat = [];
        let mapNames = {};
        china.features.forEach(item => {
          const properties = item.properties;
          mapNames[properties.id] = properties.name;
        });
        datas.mapDat.forEach(el => {
          if (el && el.adCode && el.value !== "0") {
            el.name = mapNames[el.adCode];
            this.mapDat.push(el);
          }
        });
        this.map();
      });
    }
  }
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 65vh;
  margin-top: 20px;
  background: #e0e4ec;
}
.number {
  font-size: 30px;
}
.pointer {
  cursor: pointer;
}
.contents {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.content_item {
  width: 180px;
  height: 80px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e0e4ec;
  border-radius: 3px;
  border-radius: 3px;
}
.content_item_title {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #565d75;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 20px;
}
</style>
